<template>
  <div id="wrapper" class="is-collapse">
    <Header v-on:playPlaylist="handlePlayPlaylist" :user="user" :items="items" :playMode="playMode" v-model:song-index="itemIndex" :code="code" :play-time="playTime"/>
    <Sidebar :user="user"/>
    <div class="main_content">
      <div class="mcontainer">
        <router-view v-on:playPlaylist="handlePlayPlaylist" v-on:playItem="handlePlayItem" :user="user"/>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

export default {
  name: 'Layout',
  props: {
    user: Object
  },
  data() {
    return {
      playMode: "song",
      playTime: null,
      itemIndex: 0,
      items: [],
      code: "",
      currentSong: null
    }
  },
  methods: {
    handlePlayPlaylist(items, mode, isAdded = false, prefix = "",  playTime = null) {
      console.log('handlePlayPlaylist...........', items, isAdded);
      if (this.playMode === "playlist" && mode === "song") {
        this.items = [];
      }

      if (mode === "song") {
        if (isAdded) {
          items.map((item) => {
            item.prefix = prefix;
          });
          this.items = this.items.concat(items);
        } else {
          // if (items.file_type !== "audio") {
          //   window.postMessage({action: "stopPlayer"}, window.location.origin);
          //   return;
          // }

          if (this.currentSong && this.currentSong.prefix == prefix && this.currentSong.id == items.id) {
            return;
          }

          this.playTime = playTime;
          items.prefix = prefix;
          this.itemIndex = 0;
          this.items = [items];
          this.code = this.getCode();
        }
      } else if (mode === "playlist") {
        if (!isAdded) {
          this.items = items;
          this.itemIndex = 0;
          this.code = this.getCode();
        }
      }

      this.playMode = mode;
    },
    handlePlayItem(index) {
     this.itemIndex = index;
     this.code = this.getCode();
    },
    getCode() {
      return new Date().valueOf().toString();
    }
  },
  created() {
    window.addEventListener("message", (event) => {
      if (event.data.action === "currentSong") {
        this.currentSong = event.data.value;
      }
    }, false);
  },
  watch: {
    items: {
      handler(newVal) {
        if (newVal) {
          window.$("#wrapper").removeClass("show-searchbox");
          window.$("#wrapper").addClass("hide-searchbox");
        } else {
          window.$("#wrapper").addClass("show-searchbox");
          window.$("#wrapper").removeClass("hide-searchbox");
        }
      },
      deep: true
    }
  },
  components: {
    Footer,
    Sidebar,
    Header
  }
}
</script>
